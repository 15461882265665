var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"4","sm":"3","md":"3"}},[_c('v-select',{attrs:{"label":"店點","outlined":"","dense":"","hide-details":"","clearable":"","items":_vm.branchOptions},model:{value:(_vm.searchQuery.branch),callback:function ($$v) {_vm.$set(_vm.searchQuery, "branch", $$v)},expression:"searchQuery.branch"}})],1),_c('v-col',{attrs:{"cols":"4","sm":"3","md":"3"}},[_c('v-select',{attrs:{"label":"狀態","outlined":"","dense":"","hide-details":"","items":[
          { text: '請選擇', value: '', disabled: true },
          { text: '上架中', value: 'progress' },
          { text: '已下架', value: 'finished' },
        ]},on:{"change":_vm.fillDate},model:{value:(_vm.searchQuery.status),callback:function ($$v) {_vm.$set(_vm.searchQuery, "status", $$v)},expression:"searchQuery.status"}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.searchQuery.date,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":_vm.searchQuery.status === 'finished'},on:{"update:returnValue":function($event){return _vm.$set(_vm.searchQuery, "date", $event)},"update:return-value":function($event){return _vm.$set(_vm.searchQuery, "date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formattedDate,"label":"日期區間","outlined":"","readonly":"","dense":"","hide-details":"","clearable":"","disabled":_vm.searchQuery.status === 'finished',"max":_vm.today}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":"","locale":"zh-tw"},model:{value:(_vm.searchQuery.date),callback:function ($$v) {_vm.$set(_vm.searchQuery, "date", $$v)},expression:"searchQuery.date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" 取消 ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.searchQuery.date)}}},[_vm._v(" 確定 ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.search}},[_vm._v("查詢")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"mobile-breakpoint":0,"item-key":"period._id","hide-default-footer":"","show-expand":"","footer-props":{
          itemsPerPageText: '每頁幾筆',
          itemsPerPageOptions: [-1],
        },"height":'80vh',"fixed-header":""},scopedSlots:_vm._u([{key:"item.income",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatPrice(item.income))+" ")]}},{key:"item.price",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatPrice(item.price))+" ")]}},{key:"item.amount",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatPrice(item.amount))+" ")]}},{key:"item.stockCost",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatPrice(item.stockCost))+" ")]}},{key:"item.receivedAwardCost",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatPrice(item.receivedAwardCost))+" ")]}},{key:"item.point",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatPrice(item.point))+" ")]}},{key:"item.profit",fn:function({ item }){return [_c('div',{class:item.profit > 0 ? 'red--text' : 'green--text'},[_vm._v(" "+_vm._s(_vm.formatPrice(item.profit))+" ")])]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{staticClass:"pa-4",attrs:{"colspan":headers.length}},[_c('pre',[_vm._v(_vm._s(_vm.pretty(item)))])])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }