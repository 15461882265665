<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="4" sm="3" md="3">
        <v-select
          label="店點"
          v-model="searchQuery.branch"
          outlined
          dense
          hide-details
          clearable
          :items="branchOptions"
        >
        </v-select>
      </v-col>
      <v-col cols="4" sm="3" md="3">
        <v-select
          label="狀態"
          v-model="searchQuery.status"
          outlined
          dense
          hide-details
          :items="[
            { text: '請選擇', value: '', disabled: true },
            { text: '上架中', value: 'progress' },
            { text: '已下架', value: 'finished' },
          ]"
          @change="fillDate"
        >
        </v-select>
      </v-col>
      <v-col cols="4" sm="4" md="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="searchQuery.date"
          transition="scale-transition"
          offset-y
          min-width="auto"
          :disabled="searchQuery.status === 'finished'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formattedDate"
              v-bind="attrs"
              v-on="on"
              label="日期區間"
              outlined
              readonly
              dense
              hide-details
              clearable
              :disabled="searchQuery.status === 'finished'"
              :max="today"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="searchQuery.date"
            range
            no-title
            scrollable
            locale="zh-tw"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> 取消 </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(searchQuery.date)"
            >
              確定
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="auto">
        <v-btn color="primary" @click="search">查詢</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="data"
          :mobile-breakpoint="0"
          item-key="period._id"
          hide-default-footer
          show-expand
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [-1],
          }"
          :height="'80vh'"
          fixed-header
        >
          <template v-slot:item.income="{ item }">
            {{ formatPrice(item.income) }}
          </template>
          <template v-slot:item.price="{ item }">
            {{ formatPrice(item.price) }}
          </template>
          <template v-slot:item.amount="{ item }">
            {{ formatPrice(item.amount) }}
          </template>
          <template v-slot:item.stockCost="{ item }">
            {{ formatPrice(item.stockCost) }}
          </template>
          <template v-slot:item.receivedAwardCost="{ item }">
            {{ formatPrice(item.receivedAwardCost) }}
          </template>
          <template v-slot:item.point="{ item }">
            {{ formatPrice(item.point) }}
          </template>
          <template v-slot:item.profit="{ item }">
            <div :class="item.profit > 0 ? 'red--text' : 'green--text'">
              {{ formatPrice(item.profit) }}
            </div>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-4">
              <pre>{{ pretty(item) }}</pre>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import util from "@/mixins/util";
import dayjs from "dayjs";

export default {
  name: "",
  props: [],
  mixins: [util],
  data: () => ({
    data: [],
    periods: [],
    menu: false,
    today: dayjs().format("YYYY-MM-DD"),
    searchQuery: {
      branch: "",
      status: "finished",
      date: [],
    },
    headers: [
      {
        text: "店點",
        value: "period.branch",
        align: "left",
        sortable: false,
        cellClass: "font-weight-regular",
      },
      {
        text: "機台",
        value: "period.machine",
        align: "left",
        sortable: true,
        cellClass: "font-weight-regular",
      },
      {
        text: "機台商品",
        value: "period.product.name",
        align: "left",
        sortable: false,
      },
      {
        text: "上架時間",
        value: "period.start.date",
        align: "left",
        sortable: true,
        sort: (a, b) => {
          if (dayjs(a.date).isAfter(dayjs(b.date))) {
            return -1;
          }
          if (dayjs(a.date).isBefore(dayjs(b.date))) {
            return 1;
          }
          return 0;
        },
      },
      {
        text: "下架時間",
        value: "period.end.date",
        align: "left",
        sortable: true,
        sort: (a, b) => {
          if (dayjs(a.date).isAfter(dayjs(b.date))) {
            return -1;
          }
          if (dayjs(a.date).isBefore(dayjs(b.date))) {
            return 1;
          }
          return 0;
        },
      },
      { text: "幣量", value: "income", sortable: false },
      { text: "商品單價", value: "price", sortable: false },
      { text: "庫存消耗量", value: "amount", sortable: false },
      { text: "庫存成本總額", value: "stockCost", sortable: false },
      { text: "換抽成本", value: "receivedAwardCost", sortable: false },
      { text: "點數成本", value: "point", sortable: false },
      { text: "利潤", value: "profit", sortable: false },
    ],
    branchOptions: [
      { text: "請選擇", value: "", disabled: true },
      { text: "文賢店", value: "文賢" },
      { text: "崇善店", value: "崇善" },
      { text: "奇美店", value: "奇美" },
    ],
  }),
  computed: {
    periodsMap() {
      return _.keyBy(this.periods, "_id");
    },
    formattedDate() {
      const date = this.searchQuery.date;
      return date[0] && date[1]
        ? dayjs(date[0]).format("YYYY-MM-DD") +
            " ~ " +
            dayjs(date[1]).format("YYYY-MM-DD")
        : "";
    },
  },
  methods: {
    fillDate(e) {
      if (e === "progress") {
        this.searchQuery.date = [
          dayjs().format("YYYY-MM-01"),
          dayjs().format("YYYY-MM-DD"),
        ];
        // this.searchQuery.date[0] = dayjs().format("YYYY-MM-01");
        // this.searchQuery.date[1] = dayjs().format("YYYY-MM-DD");
        return;
      }
      this.searchQuery.date = [];
      return;
    },
    async search() {
      if (!this.searchQuery.branch) return this.$toast.error("請選擇店點");

      try {
        this.$vloading.show();
        const endPoint =
          this.searchQuery.status === "progress"
            ? "profit-progress"
            : "profit-finished";

        let data = [];
        if (endPoint === "profit-finished") {
          const { data: finish } = await this.axios.get(
            `/period/${this.searchQuery.branch}/${endPoint}`
          );
          data = finish;
        } else {
          const date = this.searchQuery.date
            ? this.searchQuery.date
            : this.today;
          const { data: progress } = await this.axios.get(
            `/period/${this.searchQuery.branch}/${endPoint}`,
            {
              params: {
                dateRange: date,
              },
            }
          );
          data = progress;
        }
        this.data = data
          .filter((profit) =>
            dayjs(profit.period.start.date).isAfter("2024-02-16")
          )
          .sort((a, b) => {
            if (
              dayjs(a.period.start.date).isAfter(dayjs(b.period.start.date))
            ) {
              return -1;
            }
            if (
              dayjs(a.period.start.date).isBefore(dayjs(b.period.start.date))
            ) {
              return 1;
            }
            return 0;
          });
        this.$vloading.hide();
      } catch (error) {
        console.log(error);
        this.$toast.error(error.response?.data?.message ?? "查詢失敗");
        this.$vloading.hide();
      }
    },
    pretty: (val, indent = 2) => {
      // if (typeof val !== "object") {
      try {
        val = JSON.parse(val);
      } catch (err) {
        return val;
      }

      return JSON.stringify(val, null, indent);
      // }
    },
  },
  async created() {},
};
</script>

<style></style>
